import type { WAESAPIV1 } from "../../../../../../../../common";

import "./InvitationForm.less";

import { type FC, useState } from "react";

import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import MySelect from '@/components/basic/select';
import { ExpireDatePicker } from "@/components/basic/shared/ExpireDatePicker";
import MyForm from "@/components/core/form";
import MyFormItem from "@/components/core/form-item";
import { LocaleFormatter } from "@/locales";
import removeEmptyObjects from "@/utils/removeEmptyObjects";

interface Props {
  onSubmit: (
    signupBody: Omit<WAESAPIV1.Invitations.CreateRequest, "customerId">
  ) => void;
  solutions?: Record<string, string>;
}

const NO_SOLUTION_VALUE = "_none_";

const InvitationForm: FC<Props> = ({ onSubmit, solutions }) => {
  const [form] = MyForm.useForm();
  const [businessId, setBusinessId] = useState<string>("");

  const onFormSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    if (values.prefilledData.preVerifiedPhone?.ids) {
      values.prefilledData.preVerifiedPhone.ids =
        values.prefilledData.preVerifiedPhone.ids.split(",");
    }
  
    if (values.prefilledData.solutionID === NO_SOLUTION_VALUE) {
      delete values.prefilledData.solutionID;
    }

    values.expires = values.expires?.valueOf();

    const cleanedValues = removeEmptyObjects(values);

    const registerBody: Omit<
      WAESAPIV1.Invitations.CreateRequest,
      "customerId"
    > = {
      ...cleanedValues,
    };

    onSubmit(registerBody);
  };

  return (
    <MyForm
      form={form}
      layout="vertical"
      className="register-phone-number-form"
      scrollToFirstError
    >
      { solutions &&
      <MyFormItem
        name={["prefilledData", "solutionID"]}
        label="Multi-Partner Solution"
      >
        <MySelect
          defaultValue={NO_SOLUTION_VALUE}
          options={[
            { label: "None", value: NO_SOLUTION_VALUE },
            ...Object.entries(solutions).map(([id, label]) => {
              return { label, value: id }
            })
          ]}
        />
			</MyFormItem>
      }
      <MyFormItem
        name="code"
        label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.code" />}
        extra={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.codeExtra" />}
        rules={[
          {
            pattern: /^[0-9a-zA-Z_-]{10,100}$/g,
            message: (
              <LocaleFormatter id="whatsapp.embeddedSignup.invitations.codeValidation" />
            ),
          },
        ]}
      >
        <MyInput />
      </MyFormItem>
      <fieldset className="signup-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.businessInformation" />
        </legend>
        <MyFormItem
          name={["prefilledData", "business", "id"]}
          label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.businessId" />}
          extra={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.businessIdExtra" />
          }
        >
          <MyInput
            value={businessId}
            onChange={(e) => setBusinessId(e.target.value)}
          />
        </MyFormItem>
        {!businessId && (
          <>
            <MyFormItem
              name={["prefilledData", "business", "name"]}
              label={
                <LocaleFormatter id="whatsapp.businessName" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "email"]}
              label={
                <LocaleFormatter id="whatsapp.businessEmail" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "website"]}
              label={
                <LocaleFormatter id="whatsapp.businessWebsite" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "streetAddress1"]}
              label={
                <LocaleFormatter id="whatsapp.businessAddress1" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "streetAddress2"]}
              label={
                <LocaleFormatter id="whatsapp.businessAddress2" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "city"]}
              label={
                <LocaleFormatter id="whatsapp.businessCity" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "state"]}
              label={
                <LocaleFormatter id="whatsapp.businessState" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "zipPostal"]}
              label={
                <LocaleFormatter id="whatsapp.businessZipPostal" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "country"]}
              label={
                <LocaleFormatter id="whatsapp.businessCountry" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "phone", "code"]}
              label={
                <LocaleFormatter id="whatsapp.businessPhoneCode" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "phone", "number"]}
              label={
                <LocaleFormatter id="whatsapp.businessPhoneNumber" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "timezone"]}
              label={
                <LocaleFormatter id="whatsapp.businessTimezone" />
              }
            >
              <MyInput />
            </MyFormItem>
          </>
        )}
      </fieldset>
      <fieldset className="signup-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.phone" />
        </legend>
        <MyFormItem
          name={["prefilledData", "phone", "displayName"]}
          label={
            <LocaleFormatter id="whatsapp.phone.displayName" />
          }
        >
          <MyInput />
        </MyFormItem>
        <MyFormItem
          name={["prefilledData", "phone", "category"]}
          label={<LocaleFormatter id="whatsapp.phone.category" />}
        >
          <MyInput />
        </MyFormItem>
        <MyFormItem
          name={["prefilledData", "phone", "description"]}
          label={
            <LocaleFormatter id="whatsapp.phone.description" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <fieldset className="signup-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhone" />
        </legend>
        <MyFormItem
          name={["prefilledData", "preVerifiedPhone", "ids"]}
          label={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhone" />
          }
          extra={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhoneExtra" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <fieldset className="signup-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.whatsAppBusinessAccount" />
        </legend>
        <MyFormItem
          name={["prefilledData", "whatsAppBusinessAccount", "id"]}
          label={
            <LocaleFormatter id="whatsapp.whatsAppBusinessAccountId" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <MyFormItem
        name="expires"
        label={<LocaleFormatter id="global.expiresAt" />}
        extra={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.expiresExtra" />}
      >
        <ExpireDatePicker />
      </MyFormItem>
      <MyButton
        type="primary"
        htmlType="submit"
        size="large"
        onClick={onFormSubmit}
        className="register-button"
      >
        <LocaleFormatter id="whatsapp.embeddedSignup.invitations.generateLink" />
      </MyButton>
    </MyForm>
  );
};

export default InvitationForm;
